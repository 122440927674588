html {
    scroll-behavior: smooth;
  }
  
  .react-slider {
    width: 100%;
    height: 300px; /* Ajusta la altura según sea necesario */
    position: relative;
  }
  
  .react-slider-thumb {
    background-color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .react-slider-track {
    background-color: transparent;
  }
  
  .slide {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slide img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
